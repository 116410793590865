
import { Options, mixins } from "vue-class-component"
import Join from "@/components/Join/index.vue"
import CustomModal from "@/components/Modal/CustomModal.vue"

import { $vfm } from "vue-final-modal"
import Panelist from "@/mixins/Panelist"
@Options({
  components: {
    Join,
    CustomModal
  }
})
export default class Terminated extends mixins(Panelist) {
  eligibleModalName = "eligible-modal"

  onLinkClick(): void {
    $vfm.show(this.eligibleModalName)
  }

  hideEligibleModal(): void {
    $vfm.hide(this.eligibleModalName)
  }
}
